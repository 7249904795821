<template>
  <div class='prohibited-information-container'>
    <!--    header -->
    <b-row>
      <b-col cols="12">
        <div class="prohibited">
          <h4 class="prohibited-text">メッセージ</h4>
        </div>
      </b-col>
    </b-row>
    <!--    main-->
    <!--    section one-->
    <b-row>
      <b-col cols="12" class="">
        <div class="wrap-main section-one">
          <h4 class="prohibited-wrap-title">禁止情報</h4>
          <div class="prohibited-content mt-2">
            <b-row>
              <b-col cols="3">
                <p>連絡先</p>
              </b-col>
              <b-col cols="9">
                <ul class="prohibited-content-list">
                  <li class="prohibited-content-item">メールアドレス（@を含む英数字の組み合わせ）</li>
                  <li class="prohibited-content-item">電話番号（3桁以上の数字の組み合わせ）</li>
                </ul>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <!--    section two-->
    <b-row>
      <b-col cols="12">
        <div class="wrap-main section-two">
          <h4 class="prohibited-wrap-title">禁止キーワード</h4>
          <p class="prohibited-wrap-desc mt-1">ユーザー間のメッセージ内で送信不可のワードを設定します</p>
          <div class="prohibited-content" ref="myDataList">
            <b-table
                id="account-table"
                hover
                responsive
                :items="fobbiddens"
                :fields="fields"
                first-number
                show-empty
                table-variant="light"
                :borderless="true"
                tbody-tr-class="trTable-fobbidden-create"
            >
              <template #cell(stt)="row">
                <span>{{row.index+1}}</span>
              </template>
              <template #cell(content)="row">
                <span>{{row.item.content}}</span>
              </template>
              <template #empty>
                <span class="text-center d-block font-large-1">データがありません。</span>
              </template>
            </b-table>
          </div>
          <b-button class="btn-ok btn-open-dialog-create float-right text-white border-0" @click="changStatusModalCreate" >編集</b-button>
        </div>
      </b-col>
    </b-row>

    <!--    dialog create prohibited-->
    <Dialog
        :dialog="openModalCreate"
        :showCancelButton="false"
        :showOkButton="false"
        :hideHeader="true"
        :centered="true"
    >
      <template #header>
        <div class="px-2 mt-1 modal-header-wrap">
          <h5 class="modal-header-title">禁止キーワード</h5>
          <p class="modal-header-desc">
            ユーザー間のメッセージ内で送信不可のワードを設定します
          </p>
        </div>
      </template>
      <template #content>
        <div class="content-modal-create prohibited-content px-2" ref="myDataDialogCreate">
          <validation-observer ref="simpleRulesUpdate">
            <b-form class="mt-2" @submit.prevent>
            <div class="content-modal-wrap-input mt-1"  v-for="(fobbidden,index) in fobbiddens" :key="index">
            <span @click="getFobbidenDelete(fobbidden.id)" class="float-left lable-icon-minus mr-2">
               <feather-icon
                   icon="MinusIcon"
                   size="10"
               />
            </span>
              <div class="show-input-fobbidden">
                <validation-provider
                    #default="{ errors }"
                    name="禁句 "
                    :vid="fobbidden.id + ''"
                >
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="1"
                        label-cols-lg="1"
                        content-cols-sm="1"
                        content-cols-lg="11"
                        :label="index + 1+''"
                        :label-for="`input-${fobbidden.id}`"
                        class="modal-create-input"
                    >
                      <b-form-input v-model="fobbidden.content" :value="fobbidden.content"
                                    size="sm" :id="`input-${fobbidden.id}`"
                                    @change="updateFobb(fobbidden.id,fobbidden.content)">
                      </b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>
              </div>
            </div>
            </b-form>
          </validation-observer>
        </div>
      </template>
      <!--      form create new fobbiden-->
      <template #footer>
        <div class="form-create-fobbiden">
          <validation-observer ref="simpleRulesCreate">
            <b-form class="mt-2" @submit.prevent>
              <validation-provider
                  #default="{ errors }"
                  name="禁止キーワード"
                  rules=""
                  vid="insert"
              >
                <b-form-group
                    label-cols-sm="1"
                    label-cols-lg="1"
                    content-cols-sm="9"
                    content-cols-lg="11"
                    :label="lengthArrProhibiteds +''"
                    label-for="prohibited"
                    class="modal-create-input mt-1 ml-3"
                >
                  <b-form-input :class="errors[0] ?'errors__validated' :''"
                                v-model="newFobbidden"
                                class="input-create-fobbiden"
                                placeholder="禁止キーワードを追加する" size="sm"
                                id="prohibited"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <div class="footer-modal-wrap footer-modal mt-3">
                <b-button class="btn-cancer" @click="changStatusModalCreate">キャンセル</b-button>
                <b-button class="btn-ok border-0" @click="createProhibited">保存</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </template>
    </dialog>
    <!--            dialog delete-->
    <Dialog v-if="fobbidenDelete"
            :dialog="dialogDeleteFobbidden"
            :showCancelButton="false"
            :showOkButton="false"
            :hideHeader="true"
            :centered="true"
            :size="'md'"
    >
      <template #header>
        <h5 class="header-modal-title mt-1 mb-1">禁止キーワード削除 </h5>
      </template>
      <!--              show list fobbiden in form-->
      <template #content>
        <p>禁止キーワード（ {{ fobbidenDelete.content }} ）を削除します。よろしいですか？</p>
        <div class="footer-modal">
          <div class="footer-modal-wrap">
            <b-button class="btn-cancer" @click="dialogDeleteFobbidden = false">キャンセル</b-button>
            <b-button class="btn__delete-ok" @click="destroyedProhibited(fobbidenDelete.id)">削除</b-button>
          </div>
        </div>
      </template>
    </dialog>
  </div>
</template>

<script>
import {BButton, BCol,BRow,BFormGroup, BFormInput, BInputGroup ,BForm,BTable} from "bootstrap-vue";
import Dialog from "@core/components/dialog/Dialog";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { max, required} from "@validations";
import {apiService} from "@/services/api.service";
import {STATUS} from "@/constant/constant";
import {nextTick} from "@vue/composition-api";
export default {
  name: 'ProhibitedInformation',
  components:{
    BButton,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BInputGroup ,
    BForm,
    BTable,
    Dialog,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      fobbiddens:[],
      openModalCreate:false,
      newFobbidden: "",
      required,
      max,
      currentPage:1,
      perPage:'',
      totalPage:'',
      fobbidenDelete:'',
      dialogDeleteFobbidden:false,
      lastPage:'',
      isLoading: false,
      fields: [
        { key: 'stt', label : '',thStyle : {width : "10%"}},
        { key: 'content', label : '',thStyle: {width : "90%"}}
      ],
      formCreateScrollEl : '',
      arrUpdated: [],
      arrUpdateAndInsert : {},
      cloneFobbidents : null,
    }
  },
  methods: {
    changStatusModalCreate(){
      this.openModalCreate = !this.openModalCreate
      nextTick(()=>{
        this.formCreateScrollEl = this.$refs['myDataDialogCreate'];
        this.formCreateScrollEl.addEventListener('scroll',(e)=>{
          this.onScroll(e);
        })
        this.checkToggeClassScroll();
        // when user cancer-> restore data back to the way it was
        if (!this.openModalCreate){
          this.fobbiddens = JSON.parse(JSON.stringify(this.cloneFobbidents));
          this.newFobbidden = "";
        }
        this.arrUpdateAndInsert = {};
      })
    },
    changeModalDeleteFobbiden(){
      this.dialogDeleteFobbidden = ! this.dialogDeleteFobbidden;
    },
    // get list fobbiddens
    async getListFobbidens(){
      this.startLoading();
      this.isLoading = true;
      try{
        let res = await apiService.get(`/fobbidden-setting?page=${this.currentPage}`);
        if (res && res.data && res.data.code === STATUS.SUCCESS){
          this.fobbiddens.push(...res.data.data);
          // create a clone array when the user has cancer, it will back up the data as before
          // because array fobbiddens is an array inside with objects -> reference values
          // -> when fobbiden changes, cloneArr also changes, so we have to use JSON.parse
          this.cloneFobbidents = JSON.parse(JSON.stringify(this.fobbiddens));
          this.currentPage = res.data.pagination.current_page;
          this.lastPage = res.data.pagination.total_pages;
          this.checkToggeClassScroll();
        }else this.notifyError(res.data.message);
      }catch (e){
        this.notifyError(e.data.message);
      }finally {
        this.endLoading();
        this.isLoading = false;
      }
    },
    //create fobbidden
    async createProhibited(){
      this.$refs.simpleRulesCreate.reset();
      this.$refs.simpleRulesUpdate.reset();
      this.startLoading();
      if (this.newFobbidden){
        this.arrUpdateAndInsert.insert = this.newFobbidden;
      }else{
        this.arrUpdateAndInsert.insert = ''
      }
      try {
        let res = await apiService.post('/fobbidden-setting',this.arrUpdateAndInsert);
        if (res.data.code === STATUS.SUCCESS && res.data ){
          this.changStatusModalCreate();
          if (this.arrUpdateAndInsert.insert){
            this.fobbiddens.push({...res.data.data[0]});
            // scroll to the end of the scroll bar after adding new data to see the new element
            nextTick(()=>{
              let divEL = this.$refs['myDataList'];
              divEL.scrollTo(0,divEL.scrollHeight);
            })
          }
          this.cloneFobbidents = JSON.parse(JSON.stringify(this.fobbiddens));
          this.newFobbidden = '';
          this.arrUpdated = [];
          this.arrUpdateAndInsert = {};
          this.notifySuccess(STATUS.MESSENGER_CREATE_SUCCESS);
          this.checkToggeClassScroll();
        }else if( res.data.code === STATUS.BAD_REQUEST){
          this.$refs.simpleRulesCreate.setErrors(res.data.data);
          let idFobbiddenIsValid,contentMsg;
          idFobbiddenIsValid = contentMsg = "";
          if (this.arrUpdateAndInsert.update && this.arrUpdateAndInsert.update.length >0){
            // because there are many input tags -> don't know which field is faulty
            // should be distinguished by id : content so that $refs.simpleRulesUpdate can find it
            res.data.data.update.forEach(item =>{
                let splitMsg = item.split('-');
                let [contentMsg,idFobbiddenIsValid] = splitMsg;
              // return to an array that vee validated understands
                let newMsg = {
                  [idFobbiddenIsValid] : [contentMsg]
                }
                this.$refs.simpleRulesUpdate.setErrors(newMsg)
            })
        }
        }else{
          this.notifyError(res.data.message);
        }
      }catch (e){
        this.notifyError(e.message);
      }finally {
        this.endLoading();
      }
    },
    getFobbidenDelete(id){
      this.fobbidenDelete = this.fobbiddens.find(fobbiden => fobbiden.id === id);
      if (this.fobbidenDelete){
        this.changeModalDeleteFobbiden();
      }
    },
    async destroyedProhibited(id){
      this.startLoading();
      try {
        let res = await apiService.delete(`/fobbidden-setting/${id}`);
        if (res && res.data && res.data.code === STATUS.SUCCESS ){
          this.fobbiddens = this.fobbiddens.filter(fobbiden => fobbiden.id !== res.data.data.id);
          this.cloneFobbidents = JSON.parse(JSON.stringify(this.fobbiddens));
          this.notifySuccess(STATUS.MESSENGER_DELETE_SUCCESS);
          this.changeModalDeleteFobbiden();
          this.checkToggeClassScroll();
        }else this.notifyError(res.data.message);
      }catch (e){
        this.notifyError(e.data.message);
      }finally {
        this.endLoading();
      }
    },
    onScroll(event){
      if ( event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight){
        if (!this.isLoading) {
          if (this.currentPage >= this.lastPage){
            return;
          }
          this.currentPage++;
          this.getListFobbidens();
        }
      }
    },
    checkToggeClassScroll(){
      this.formCreateScrollEl = this.$refs['myDataDialogCreate'];
      if (this.fobbiddens.length > 6){
        this.$refs['myDataList'].classList.add('scroll-active');
      }else{
        this.$refs['myDataList'].classList.remove('scroll-active');
      }
      if (this.fobbiddens.length > 6){
        this.formCreateScrollEl.classList.add('scroll-active')
      }else {
        this.formCreateScrollEl.classList.remove('scroll-active')
      }
    },
    updateFobb(id,content){
      this.arrUpdated.push(
          {
            id : id,
            content : content
          }
      )
      this.arrUpdateAndInsert = {
        update : this.arrUpdated,
      }

      // reset array this.arrUpdateAndInsert
      let ids = this.arrUpdateAndInsert.update.map(i=>i.id);
      this.arrUpdateAndInsert.update = this.arrUpdateAndInsert.update.filter(({id},index)=> !ids.includes(id,index + 1));
    },
  },
  computed:{
    lengthArrProhibiteds(){
      return this.fobbiddens.length + 1
    }
  },
  mounted() {
    this.getListFobbidens();
    let scrollDataEl = this.$refs['myDataList'];
    scrollDataEl.addEventListener('scroll',(e)=>{
      this.onScroll(e);
    });
  },
  beforeDestroy() {
    let scrollDataEl = this.$refs['myDataList'];
    scrollDataEl.removeEventListener('scroll',this.onScroll);
    if (this.formCreateScrollEl){
      this.formCreateScrollEl.removeEventListener('scroll',this.onScroll);
    }
  },
}
</script>

<style lang='scss' scoped>
.prohibited{
  font-size: 16px;
  border-bottom: 1px solid #E0E0E0;
  position: relative;
  .prohibited-text{
    color: #6F767E;
  }
}
.prohibited::after{
  display: block;
  content: "";
  background-color: #377DFF;
  width: 100px;
  height: 2px;
}
.section-one{
  padding: 20px 30px;
}
.section-two{
  padding: 30px 30px 60px 30px;
}
.wrap-main{
  background-color: #ffffff;
  margin-top: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  .prohibited-wrap-title{
    font-weight: 800;
    font-size: 18px;
    color: #000000;
  }
  .prohibited-content-list{
    padding-left: 0;
  }
  .prohibited-content-item{
    list-style: none;
    margin-bottom: 10px;
  }
}
.list-section-two{
  .prohibited-content-item{
    border-bottom: 1px solid #E0E0E0;
    padding:5px 10px;
  }
}
.btn-ok{
  background-color: #377DFF!important;
  padding:10px 25px;
  border-radius: 6px;
  bottom: 10px;
}
//style modal create prohibeted
.modal-header-wrap{
  border-bottom: 1px solid #E0E0E0;
  .modal-header-title{
    font-weight: 700;
    font-size: 18px;
  }
  .modal-header-desc{
    font-weight: 500;
  }
}
.content-modal-wrap-input{
  border-bottom: 1px solid #e0e0e0;
  .lable-icon-minus{
    position: relative;
    top: 6px;
    background-color: #DC0000;
    color: #ffffff;
    border-radius: 10px;
    padding: 0 5px;
    cursor: pointer;
  }
}
.footer-modal-wrap{
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  .btn-cancer{
    background-color: #ffffff !important;
    color: #000000 !important;
    margin-right: 10px;
    border: 1px solid #EDEDED !important;
  }
  .btn__cancer-modal{
    background-color: #ffffff !important;
    color: #000000 !important;
    margin-right: 10px;
    padding-top: 9px;
    border: 1px solid #EDEDED !important;
  }
  .btn__delete-ok{
    background-color: #DC0000!important;
    border: none;
  }
  .btn-cancer:hover{
    box-shadow: none;
  }
}
.content-modal-create{
  max-height: 400px;
}
.form-create-fobbiden{
  padding-right: 40px;
  padding-left: 18px;
}
.btn-open-dialog-create{
  margin-top:20px;
  margin-right: 5px;
}
.section-two .prohibited-content{
  max-height: 300px;
}
.scroll-active{
  overflow-y: scroll;
}
</style>
